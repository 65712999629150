import React, { useState } from 'react';
import cx from 'classnames';
import { formatCurrency, convertNumberToString } from '../../../utils/formatNumber';
import { formatKm } from '../../../utils/mask';
import { Datasheet } from './components/Datasheet';
import { OptionalsItems } from './components/Optionals';
import styles from './DataAdvertisement.module.css';
import { vehicleDescription } from './utils/getInfo';

interface VehicleSituationOptions {
  ipva: string;
  factoryWarranty: string;
  owner: string;
}
interface OptionsDataSheet {
  model: string;
  year: string;
  yearFabrication: string;
  odometer: number;
  price: string;
  color: string;
  motor: number;
  fuel: string;
  transmission: string;
  typeVehicle: string;
  about: string;
  type?: string;
  numberDoors: number;
  vehicleSituation?: VehicleSituationOptions;
}
interface Props {
  dataSheet: OptionsDataSheet;
  dataOptionals: any;
}
export const DataAdvertisement = ({ dataSheet, dataOptionals }: Props) => {
  const [truncateAbout, setTruncateAbout] = useState(true);
  const { model, year, yearFabrication, odometer, price, about, type } = dataSheet;

  const handleClick = () => setTruncateAbout(!truncateAbout);
  const handleSeeAboutTruncate = () => {
    const truncate = about.slice(0, 200);
    return truncateAbout ? truncate : about;
  };

  const renderButtonSeeMore = () => {
    if (about === '--' || about.length <= 200) {
      return null;
    }
    return (
      <button
        type="button"
        className="f14 fw7 ttu bn abbey pointer underline bg-transparent"
        onClick={handleClick}
        aria-label="Ver o restante da descrição do anúncio"
        title="Ver o restante da descrição do anúncio"
      >
        Ver Mais
      </button>
    );
  };

  const renderTextAbout = () => {
    return (
      <>
        <p className="f14 fw3 abbey ma0 lh-copy mw6-m mw6-l">{handleSeeAboutTruncate()}</p>
        <p className="f14 fw3 abbey ma0 mt12 lh-copy">{renderButtonSeeMore()}</p>
      </>
    );
  };

  const odometerFormatted = formatKm(convertNumberToString(odometer));
  const priceFormatted = formatCurrency(price);

  const boxAd = cx('relative pb40 pb16-ns', styles.boxAd);
  const textAbout = cx('dn-ns db-m db-l', styles.textAbout);

  return (
    <section className="pt32 pt40-ns pb32 pb48-ns pb0-m pb0-l relative">
      <section className={boxAd}>
        <h1 className="abbey f20 f30-ns fw7 ma0 mb12 mb8-ns">{model}</h1>
        <div className="flex mb8 mb16-ns">
          <p className="f14 f16-ns boulder fw3 ttu ma0 mr24">
            {yearFabrication}/{year}
          </p>
          <p className="f14 f16-ns boulder fw3 ttu ma0">
            {odometerFormatted ? `${odometerFormatted} KM` : ''}
          </p>
        </div>
        <p className="f24 f36-ns fw7 abbey ma0">{priceFormatted}</p>
      </section>

      <Datasheet data={dataSheet} />

      <OptionalsItems dataOptionals={dataOptionals} />

      <section className="pt24">
        <h2 className="f16 fw6 abbey ma0 mb4">Mais sobre {vehicleDescription(type)}</h2>
        <div className={textAbout}>{renderTextAbout()}</div>
        <p className="dn db-ns dn-m dn-l f14 fw3 abbey ma0 lh-copy">{about}</p>
      </section>
    </section>
  );
};
