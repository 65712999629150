import React, { ChangeEvent, FormEvent } from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { cellPhone, removeSpecialCharacters, onlyLetters } from '../../utils/mask';
import { Button } from '../Button';
import { TextArea } from '../TextArea';
import { TextInput } from '../TextInput';

interface Props {
  errors: any;
  values: any;
  title: string;
  isSubmitting: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onClick?: any;
  dataValue?: string;
}

const customInputComponent = ({ divClass, field, ...props }: any) => {
  const normalizeFieldData = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    errorMessage: !!props.form.touched[field.name] && props.form.errors[field.name],
  };
  const errorsInput = cx({
    mb16:
      !props.form.touched[field.name] ||
      (!props.form.errors[field.name] && !!props.form.touched[field.name]),
    mb8: !!props.form.touched[field.name] && props.form.errors[field.name],
  });
  return (
    <div className={errorsInput}>
      <TextInput {...normalizeFieldData} {...props} />
    </div>
  );
};

const customTextAreaComponent = ({ divClass, field, ...props }: any) => {
  const normalizeFieldData = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    errorMessage: !!props.form.touched[field.name] && props.form.errors[field.name],
  };
  const errorsInput = cx({
    mb24:
      !props.form.touched[field.name] ||
      (!props.form.errors[field.name] && !!props.form.touched[field.name]),
    mb16: !!props.form.touched[field.name] && props.form.errors[field.name],
  });
  return (
    <div className={errorsInput}>
      <TextArea {...normalizeFieldData} {...props} />
    </div>
  );
};

export const FormProposal = ({
  title,
  isSubmitting,
  handleSubmit,
  handleChange,
  values,
  dataValue,
}: Props) => {
  const { name, email, phone, proposal } = values;
  const handleOnClick = () => {
    trackCustomEvent({
      action: 'clique',
      category: 'botao',
      label: 'proposta',
    });
  };
  return (
    <form onSubmit={handleSubmit} className="w-100 ph8 ph32-ns pv24 ">
      <Field
        name="name"
        id={title + 'name'}
        labeltext="Nome"
        type="text"
        iconleft="SvgIconName"
        iconId="proposta"
        value={name}
        onChange={handleChange}
        component={customInputComponent}
        mask={onlyLetters}
        required
      />
      <Field
        name="email"
        id={title + 'email'}
        labeltext="E-mail"
        type="text"
        iconleft="SvgIconMail"
        iconId="proposta"
        value={email}
        onChange={handleChange}
        component={customInputComponent}
        mask={removeSpecialCharacters}
        required
      />
      <Field
        name="phone"
        id={title + 'phone'}
        labeltext="Telefone"
        type="text"
        iconleft="SvgIconPhone"
        iconId="proposta"
        value={phone}
        onChange={handleChange}
        component={customInputComponent}
        mask={cellPhone}
        required
      />
      <Field
        name="proposal"
        id={title + 'proposal'}
        title="Proposta"
        labeltext="Proposta"
        onChange={handleChange}
        value={proposal}
        component={customTextAreaComponent}
        maxLength={500}
        required
      />
      <Button
        disabled={isSubmitting}
        loading={isSubmitting}
        title="Enviar proposta"
        aria-label="Enviar proposta"
        classButton={'gtmSendProposal'}
        data-value={`${dataValue}`}
        onClick={handleOnClick}
      >
        ENVIAR
      </Button>
    </form>
  );
};
