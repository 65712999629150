import React from 'react';
import { Proposal } from './Proposal';

interface Props {
  initialTextAreaValue?: string;
  adId: number;
  dataValue?: string;
}
export const SendProposal = ({
  initialTextAreaValue = 'Olá, tenho interesse no veículo. Por favor entre em contato.',
  adId,
  dataValue,
}: Props) => {
  return <Proposal initialTextAreaValue={initialTextAreaValue} adId={adId} dataValue={dataValue} />;
};
