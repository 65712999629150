import { axiosInstanceWithAuth } from '../../../services/axios-instance';

export const SendProposalService = {
  postProposal: (params: any) =>
    axiosInstanceWithAuth.post(
      '/proposal',
      { ...params },
      { headers: { 'Content-Type': 'application/json' } },
    ),
};
