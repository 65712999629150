import React, { Component } from 'react';
import { CallNow } from '../../components/CallNow';
import { Container } from '../../components/Container';
import { GridCell } from '../../components/GridCell';
import { LoaderFullPage } from '../../components/Loaders';
import { Observations } from '../../components/Observations';
import { SendProposal } from '../../components/SendProposal';
import { SharedModal } from '../../components/SharedModal';
import { SummaryShopData } from '../../components/ShopData';
import { DataAdvertisement } from '../Advertisement/DataAdvertisement';
import { Gallery } from '../Advertisement/Gallery';
import { Footer } from '../Footer';
import { SectionSix } from '../Home/components/SectionSix';
import { Menu } from '../Menu';
import { Top, Bottom } from '../Menu/components/MenuAd';
import { advertisement } from '../VehicleRegistration/services/advertisement';
import { RegisterViewsServices, AdvertisementService } from './services/AdvertisementService';
import { formatterAdvertisement } from './utils/FormatterAdvertisement';

interface Props {
  advertisement: any;
  queryParam: string;
  idPage: number;
}
export class Advertisement extends Component<Props> {
  state = {
    data: {
      gallery: { photos: [] },
      dataSheet: {
        about: '',
        color: '',
        fuel: '',
        model: '',
        motor: 0,
        numberDoors: 0,
        odometer: 0,
        price: '',
        transmission: '',
        typeVehicle: '',
        year: '',
        yearFabrication: '',
      },
      user: { companyName: '', name: '', phone: '', whatsapp: '', fantasyName: '', type: '' },
      address: {
        bgColor: '',
        urlImg: '',
        nameStore: '',
        phone: '',
        s3: '',
        address: {
          city: '',
          cepAddress: '',
          district: '',
          street: '',
          additionalAddressData: '',
        },
        type: '',
        id: 0,
      },
      optionals: {},
      hasToCall: false,
      shareData: {},
    },
    loading: true,
    openModal: false,
    adId: 0,
  };

  async componentDidMount() {
    const { advertisement } = this.props;
    RegisterViewsServices.postCountViews(advertisement.autoscarId || advertisement.id);
    const newData = formatterAdvertisement(advertisement);
    const newestPrice = await this.getCarPrice(advertisement);
    newData.dataSheet.price = newestPrice;
    this.setState({ data: newData, loading: false });
  }

  getCarPrice = async (adv: any): Promise<any> => {
    const res = await AdvertisementService.getAdvertisement(adv.autoscarId || adv.id);
    const { price } = res.data;
    return price;
  };

  setPhoneorWhatsapp = () => {
    const { data } = this.state;
    const { user } = data;
    const { phone, whatsapp } = user;
    return whatsapp === '--' ? phone : whatsapp;
  };

  toggleModalStore = (e?: any) => {
    e.stopPropagation();
    const { openModal } = this.state;
    this.setState({ openModal: !openModal });
  };

  getAliasName = () => {
    const { data } = this.state;
    const { user } = data;
    const { name, fantasyName, type } = user;
    return type === '1' ? name : fantasyName;
  };

  render() {
    const { idPage } = this.props;
    const { data, loading, openModal } = this.state;
    const { gallery, dataSheet, address, optionals, hasToCall, shareData } = data;
    const { price } = dataSheet;

    const { model, yearFabrication, year } = dataSheet;
    const dataValue = `${this.getAliasName()} ${model} ${yearFabrication}/${year}`;

    if (loading) {
      return <LoaderFullPage />;
    }
    const cellPhone = this.setPhoneorWhatsapp();
    return (
      <>
        <div className="db db-ns dn-m dn-l">
          <Top handleShare={this.toggleModalStore} dataValue={dataValue} />
          <Bottom
            name={this.getAliasName()}
            price={price}
            alwaysVisible
            cellPhone={cellPhone}
            isWhattsApp={!hasToCall}
            dataValue={dataValue}
            adId={idPage}
          />
        </div>
        <div className="dn db-m db-l">
          <Menu menuHasTwoColors={false} />
        </div>
        <div className="bg-wild-sand">
          <div className="mt48 mt72-m mt72-l">
            <Gallery
              gallery={gallery.photos}
              onSharedClick={this.toggleModalStore}
              model={dataSheet.model}
              color={dataSheet.color}
              dataValue={dataValue}
            />
          </div>
          <Container>
            <GridCell width={[1, 605, 1]} className="center mb48 mb48-m pb8-m pb24-l flex-m flex-l">
              <GridCell width={[0, 0, 0, 1 / 12]} />
              <GridCell width={[1, 1, 7 / 12, 6 / 12]}>
                <DataAdvertisement dataSheet={dataSheet} dataOptionals={optionals} />
              </GridCell>
              <GridCell
                width={[1, 1, 5 / 12, 4 / 12]}
                className="flex-column-m flex-column-l ml24-m ml24-l mt32-m mt32-l"
              >
                <div className="dn db-m db-l">
                  <div className="mb32-m mb32-l">
                    <CallNow
                      hasToCall={hasToCall}
                      name={this.getAliasName()}
                      type="big"
                      numberPhone={this.setPhoneorWhatsapp()}
                      dataValue={dataValue}
                      adId={idPage}
                    />
                  </div>
                </div>
                <SendProposal adId={idPage} dataValue={dataValue} />
                <div className="mt24 mt32-m">
                  <SummaryShopData dataValue={dataValue} dataAddress={address} />
                </div>
              </GridCell>
              <GridCell width={[0, 0, 0, 1 / 12]} />
            </GridCell>
            <SectionSix store={{ userId: address.id }} />
            <Observations />
          </Container>
          <div className="mb48 mb0-m mb0-l pb8 pb0-m pb0-l">
            <Footer />
          </div>
        </div>
        <SharedModal
          typeShared="anúncio"
          isOpenModal={openModal}
          controlModal={this.toggleModalStore}
          dataShare={shareData}
        />
      </>
    );
  }
}
