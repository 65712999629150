import React, { useState, FunctionComponent } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import { Collapse } from 'react-collapse';
import { removePhoneMask } from '../../utils/mask';
import { validationSendProposal } from '../../utils/validationForm';
import { Icon } from '../Icons';
import { MediaMobile, MediaTablet, MediaDesktop } from '../MediaWrapper';
import { ModalSuccessAndError } from '../ModalSuccessAndError';
import { FormProposal } from './FormProposal';
import { SendProposalService } from './services/SendProposalService';
import styles from './style.module.css';

interface Props {
  initialTextAreaValue: string;
  adId: number;
  dataValue?: string;
}

const TitleMobile: FunctionComponent = ({ children }) => (
  <>
    <MediaMobile>{children}</MediaMobile>
    <MediaTablet>{children}</MediaTablet>
  </>
);

const TitleDesktop: FunctionComponent = ({ children }) => (
  <>
    <MediaDesktop>{children}</MediaDesktop>
  </>
);

export const Proposal = ({ initialTextAreaValue, adId, dataValue }: Props) => {
  const [modalMessage, setModalMessage] = useState('');
  const modalErrorConfig = {
    success: false,
    title: 'Ops!',
    subTitle: 'Ocorreu um erro na sua solicitação',
  };
  const modalSuccesConfig = {
    success: true,
    title: 'Parabéns!',
    subTitle: 'Proposta enviada com sucesso!',
  };
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState(modalErrorConfig);

  const closeModal = () => {
    setModalOpen(false);
  };
  const onSubmit = async (values: any, action: any) => {
    const { name, email, phone, proposal } = values;
    const proposalValues = {
      advertisementId: adId,
      name,
      email,
      phone: removePhoneMask(phone),
      description: proposal,
    };
    await SendProposalService.postProposal(proposalValues)
      .then(() => {
        action.resetForm();
        setModalConfig(modalSuccesConfig);
        setModalMessage('Obrigado.');
        setModalOpen(true);
      })
      .catch(error => {
        const { response } = error;
        let messageModal = 'Tente novamente mais tarde';
        if (response && response.status !== 500 && response.data && response.data.message) {
          messageModal = response.data.message;
        }
        setModalConfig(modalErrorConfig);
        setModalMessage(messageModal);
        setModalOpen(true);
      });
    await action.setSubmitting(false);
  };

  const handleClickOpenForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const renderIcon = () => {
    const IconClass = cx(styles.transitionRotate, {
      'rotate-180': isFormOpen,
    });
    const divIconClass = cx('flex dn-m dn-l justify-center items-center', styles.divIconClass);
    return (
      <div className={divIconClass}>
        <Icon name="SvgIconArrowDown" customId="proposal" className={IconClass} />
      </div>
    );
  };

  const renderTopContent = () => {
    return (
      <>
        <div className="flex items-center">
          <Icon name="SvgIconDollar" height={35} width={35} />
          <h2 className="ma0 fw2 f18 f26-ns pl8">
            Fazer uma <span className="fw7">proposta</span>
          </h2>
        </div>
        {renderIcon()}
      </>
    );
  };
  const mainClass = cx('ba b--red bw2 br10 bg-white', styles.mainClass);
  const proposalTop = cx('ph12 items-center justify-between red', {
    pv8: !isFormOpen,
    'pt16 pt24-m pt24-l': isFormOpen,
  });
  const proposalTopMobile = cx('flex flex-ns dn-m dn-l', proposalTop);
  const proposalTopDesktop = cx('dn dn-ns flex-m flex-l', proposalTop);
  const { success, subTitle, title } = modalConfig;

  return (
    <>
      <div className={mainClass}>
        <TitleMobile>
          <div className={proposalTopMobile} onClick={handleClickOpenForm}>
            {renderTopContent()}
          </div>
        </TitleMobile>
        <TitleDesktop>
          <div className={proposalTopDesktop}>{renderTopContent()}</div>
        </TitleDesktop>
        <Collapse isOpened={isFormOpen} className={styles.openClosed}>
          <Formik
            onSubmit={onSubmit}
            initialValues={{ name: '', email: '', phone: '', proposal: initialTextAreaValue }}
            validationSchema={validationSendProposal}
            // tslint:disable-next-line: jsx-no-lambda
            render={props => <FormProposal title="proposal-" dataValue={dataValue} {...props} />}
          />
        </Collapse>
      </div>
      <ModalSuccessAndError
        className={styles.modalSize}
        subTitle={subTitle}
        title={title}
        text={modalMessage}
        success={success}
        isOpen={modalOpen}
        textBtnClose="OK"
        closeModal={closeModal}
      />
    </>
  );
};
