import React, { useEffect } from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import { callAll } from '../../utils/callAll';
import { Icon } from '../Icons';
import styles from './style.module.css';

interface Props {
  name: string;
  disabled?: boolean;
  title: string;
  maxLength: number;
  errorMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  rows?: number;
}

export const TextArea = ({
  disabled,
  name,
  title,
  maxLength,
  errorMessage,
  onChange,
  onFocus,
  onBlur,
  value = '',
  rows = 5,
}: Props) => {
  const [textAreaValue, setValue] = useState(value);
  const [textAreaFocus, setFocusTextArea] = useState(false);
  useEffect(() => {
    setValue(value);
  }, [value]);
  const labelStyle = classnames('f14 absolute abbey mt16 ml12', styles.label, {
    [styles.animation]: textAreaFocus || textAreaValue,
  });

  const textareaBorder = classnames(
    'ba b--gray abbey f14 ph12 pb12 pt24 fw5-1 lh-copy br3',
    styles.resizeNone,
    {
      'b--abbey bg-alto': disabled,
      'b--purpley-pink bw1': errorMessage,
      'br8 outline-0': textAreaFocus && !errorMessage,
    },
  );

  const wrapper = classnames('f16 slate-grey relative', styles.wrapper);

  const defaultOnFocus = () => {
    return setFocusTextArea(true);
  };

  const defaultOnBlur = () => {
    return setFocusTextArea(false);
  };

  const defaultOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const containerClass = classnames('w-100', textareaBorder);
  const counterClass = classnames('absolute right-1 boulder f12 z-1', styles.counter);

  return (
    <section className={wrapper}>
      <label htmlFor={`textarea${title}`} className={labelStyle}>
        {title}
      </label>
      <textarea
        id={`textarea${title}`}
        name={name}
        className={containerClass}
        disabled={disabled}
        maxLength={maxLength}
        onFocus={callAll(onFocus, defaultOnFocus)}
        onBlur={callAll(onBlur, defaultOnBlur)}
        onChange={callAll(onChange, defaultOnChange)}
        value={textAreaValue}
        rows={rows}
      />
      {errorMessage && <p className="purpley-pink mv0 mh12 f11">{errorMessage}</p>}
      {errorMessage && <Icon name="SvgIconPurpleError" className="absolute top--8 right--8" />}
      <div className={counterClass}>
        {textAreaValue?.length ?? 0}/{maxLength}
      </div>
    </section>
  );
};
