import React from 'react';
import cx from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import map from 'lodash/map';
import { Grid } from '../../../../components/Grid';
import { GridCell } from '../../../../components/GridCell';
import { Nodes, DirectusOptional, Optional } from '../../../../types/graphql';
import styles from '../DataAdvertisement.module.css';
import { formattedOptionalItems } from '../utils/formattedOptionals';

interface Props {
  dataOptionals: any;
  data?: Nodes<Optional>;
}
export const RenderOptionalsItems = ({ data, dataOptionals }: Props) => {
  const containerAd = cx('pt24 pb24 relative', styles.boxAd);
  const optionals = 'f14 fw3 boulder ma0 mb4';
  const newData = formattedOptionalItems(data, dataOptionals);

  const renderListItemns = (itemsOptionals: any) => {
    return map(itemsOptionals, (items, idx) => (
      <GridCell key={`${items.name}-${idx}`} width={[1, 1 / 4]} className="pr24">
        <p className={optionals}>{items.name}</p>
      </GridCell>
    ));
  };
  const renderListOptional = () => {
    return map(newData, (item, idx) => (
      <section key={`${item.title}-${idx}`} className={containerAd}>
        <h2 className="f16 fw6 abbey ma0 mb8 mb4-ns">{item.title}</h2>
        <Grid className="flex flex-column flex-row-ns">
          {renderListItemns(item.categoryOptionals)}
        </Grid>
      </section>
    ));
  };

  return <div>{renderListOptional()}</div>;
};

export const OptionalsItems = ({ dataOptionals }: Props) => {
  const optionalList = graphql`
    query {
      allDirectusOptional(filter: { status: { eq: "published" } }) {
        nodes {
          id
          name
          category
          featured
        }
      }
    }
  `;
  const renderOptionalsItems = (data: DirectusOptional) => {
    const {
      allDirectusOptional: { nodes },
    } = data;
    return <RenderOptionalsItems dataOptionals={dataOptionals} data={nodes} />;
  };
  return <StaticQuery query={optionalList} render={renderOptionalsItems} />;
};
