import { uniqBy } from 'lodash';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

const extractOptionalsByCategory = (group: any) => {
  const itemsByCategory = map(group, item => {
    return {
      name: item.name,
    };
  });
  return itemsByCategory;
};

const formattedListOptionalsData = (optionalGroup: any) => {
  const formattedListOptionals = map(optionalGroup, (group, index) => {
    return {
      title: index,
      categoryOptionals: extractOptionalsByCategory(group),
    };
  });

  return formattedListOptionals;
};

const groupByCategory = (objectEquals: any) => {
  const flatItemsByCategory = flatten(objectEquals);

  const optionalItemsByGroup = groupBy(flatItemsByCategory, 'category');
  return optionalItemsByGroup;
};

export const formattedOptionalItems = (optionals: any, dataOptionals: any) => {
  const objectEqualsFormatted = map(dataOptionals, item => {
    const filterEqual = uniqBy(filter(optionals, { name: item }), 'category');
    const formatEqual = map(filterEqual, (itemEqual: any) => {
      return {
        category: itemEqual.category,
        name: itemEqual.name,
        featured: itemEqual.featured,
      };
    });
    return formatEqual;
  });
  const optionalItemsByGroup = groupByCategory(objectEqualsFormatted);
  const formatted = formattedListOptionalsData(optionalItemsByGroup);
  return formatted;
};
