import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Button } from '../../../components/Button';
import { Layout } from '../../../components/Layout';
import { Footer } from '../../Footer';
import { Menu } from '../../Menu';
import styles from './styles.module.css';

export const NotFoundAdvertisement = () => (
  <Layout>
    <div className="min-vh-100">
      <Menu menuHasTwoColors={false} />
      <div
        className={classNames(
          styles.content,
          'w-100 bg-wild-sand flex items-center justify-center pl16 pr16',
        )}
      >
        <div className="flex items-center justify-center flex-column tc">
          <h1 className="f24 f32-ns">Anúncio não encontrado</h1>
          <h3 className="f16 f20-ns mb32 measure">
            Não foi possível encontrar esse anúncio. Pode ser que ele não esteja mais disponível ou
            foi excluído pelo anunciante.
          </h3>
          <Button>
            <Link className="no-underline color-inherit" to="/anuncios/busca/?categoria=Carros">
              Faça uma nova busca
            </Link>
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  </Layout>
);
