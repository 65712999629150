import React, { useState, useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import _first from 'lodash/first';
import _last from 'lodash/last';
import {
  WithContext,
  Vehicle,
  QuantitativeValue,
  ItemAvailability,
  Organization,
} from 'schema-dts';
import { Layout } from '../components/Layout';
import { LoaderFullPage } from '../components/Loaders';
import { Advertisement } from '../modules/Advertisement';
import { NotFoundAdvertisement } from '../modules/Advertisement/NotFound';
import { AdvertisementService } from '../modules/Advertisement/services/AdvertisementService';

export interface State {
  model: string;
  year: string;
  odometer: string;
  price: string;
  yearFabrication: string;
  fuel: string;
  typeVehicle: string;
  about: string;
  numberDoors: number;
  color: string;
  transmission: string;
  image: string;
  brand: string;
  id: string;
}

const getModel = (model: any) =>
  `${model.brandName || '--'} ${model.name || '--'} ${model.version || ''}`;

const getPhotoUrl = (photoUrl: string | undefined, returnUndefined?: boolean) => {
  if (!photoUrl && returnUndefined) {
    return undefined;
  }

  if (!photoUrl) {
    return `${process.env.FTP_IMAGENS}images/ad/image-veiculodefault-large.png`;
  }

  return `${process.env.FTP_IMAGENS}thumbs/${photoUrl}`;
};

const createSchema = (advertisement: any, url: string): WithContext<Vehicle> => {
  const {
    model: { brandName, modelYear, fabricationYear },
    model,
    milage = 0,
    price,
    fuelType,
    bodyStyle,
    description,
    ports,
    color,
    transmission,
    photoUrl,
  } = advertisement;

  const image = getPhotoUrl(_first(photoUrl));

  return {
    '@context': 'https://schema.org',
    '@type': 'Vehicle',
    name: getModel(model),
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency: 'BRL',
      availability: 'InStock' as ItemAvailability,
      priceValidUntil: new Date().toDateString(),
      url,
    },
    productionDate: fabricationYear,
    vehicleModelDate: modelYear,
    fuelType,
    bodyType: bodyStyle,
    mileageFromOdometer: { value: milage, unitCode: 'KMT' } as QuantitativeValue,
    description,
    numberOfDoors: ports,
    color,
    vehicleTransmission: transmission,
    image,
    brand: {
      name: brandName,
    } as Organization,
  };
};

const getPath = (location: any) => {
  const path = location.href;

  if (path.substr(path.length - 1) === '/') {
    return path.substr(0, path.length - 1);
  }
  return path;
};

interface Props extends PageProps {
  data: {
    autoscarAdvertisement: any;
  };
  pageContext: {
    id: number;
  };
}

const AdvertisementPage = ({ ...props }: Props) => {
  const {
    data: { autoscarAdvertisement },
    pageContext: { id },
  } = props;

  const advState = autoscarAdvertisement.autoscarId === id ? autoscarAdvertisement : {};
  const [advertisement, setAdvertisement] = useState<any>(advState);
  const [idPage, setIdPage] = useState<string | undefined>();
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    const pathId: string = _last(getPath(props.location).split('/')) || '';
    setIdPage(pathId);
  }, []);

  useEffect(() => {
    if (advertisement.id || advertisement.autoscarId) {
      return () => undefined;
    }

    const getAdvertisement = async () => {
      const pathId: string = _last(getPath(props.location).split('/')) || '';

      const { data } = await AdvertisementService.getAdvertisement(pathId);

      if (data.statusCode === 404) {
        setNotFound(true);
      } else {
        setAdvertisement(data);
      }
    };

    getAdvertisement();

    return () => undefined;
  }, [autoscarAdvertisement, id]);

  const { model = {}, mileage = 0, photoUrl, city, state } = advertisement;

  if (notFound) {
    return <NotFoundAdvertisement />;
  }

  if (!advertisement.id && !advertisement.autoscarId) {
    return <LoaderFullPage />;
  }

  const queryParam = props.location.pathname;
  const productSchema = createSchema(autoscarAdvertisement, props.location.href);
  const image = getPhotoUrl(_first(photoUrl), true);

  const cityState = city ? ` em ${city}-${state} ` : null;

  return (
    <Layout
      title={`${getModel(model)} ${cityState} | Autoscar`}
      // tslint:disable-next-line: jsx-no-multiline-js
      description={`${getModel(model)}, ${
        model.fabricationYear
      }, ${mileage}km. As melhores ofertas para comprar carros estão na Autoscar`}
      schema={productSchema}
      image={image}
      url={props.location.href}
      keywords={getModel(model)}
    >
      <Advertisement
        advertisement={advertisement}
        queryParam={queryParam}
        idPage={Number(idPage)}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($id: Int) {
    autoscarAdvertisement(autoscarId: { eq: $id }) {
      price
      autoscarId
      bodyStyle
      city
      color
      create_date
      description
      fuelType
      mileage
      model {
        autoscarId
        brandName
        fabricationYear
        modelYear
        name
        price
        status
        type
        version
      }
      optionals
      photoUrl
      plate
      ports
      state
      transmission
      user {
        autoscarId
        name
        additionalAddressData
        address
        addressDistrict
        addressNumber
        cep
        city
        companyName
        email
        fantasyName
        phone
        state
        type
        urlCompanyLogo
        urlFrontagePhoto
        whatsapp
      }
    }
  }
`;

export default AdvertisementPage;
